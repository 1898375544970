<script lang="ts" setup>
import { CmsBlock } from "@shopware-pwa/types";
import { pascalCase } from "scule";
import { getCmsLayoutConfiguration, getBackgroundImageUrl } from "@shopware-pwa/helpers-next";
import { twMerge } from "tailwind-merge";
import { resolveCmsComponent } from "@shopware-pwa/composables-next";

/**
 * IMPORTANT: For some CMS components we cannot use dynamic rendering since this is under the hood using AsyncComponent
 *
 * Issue: https://github.com/nuxt/nuxt/issues/26434
 *
 * So for now (temporarily) we do the following:
 *
 * 1. Copy & Paste all (block) cms components from original repo (packages/cms-base) into this repo - just for better overview
 * 2. Add all (block) cms components to the list of imports
 * 3. Comment in all (block) cms components that should be rendered dynamic
 * 4. Add all (block) cms components that should be statically renderd to the `staticComponents` array
 * 5. Add each (block) cms component to template section
 *
 * This ensures that the listed (and imported) components in `staticComponents` are registered and used statically with
 * the downside of bloating js independing of its usage. But on the other hand this is (IMO and for the moment) the only
 * working solution when using server components in our use case.
 *
 * Since the CMS components are registered globally (see nuxt.config.ts) they are either used from the NPM package
 * @shopware-pwa/cms-base or from within `components/global/cms/element/...`. For a better overview about whats used
 * from where all files from @shopware-pwa/cms-base cms components have been copied into this project having (partially)
 * empty template without functionality. Those empty components are getting dynamically rendered. So if we need them, we
 * have to lookup its content / functionality in the original sources.
 *
 */

import CmsBlockArticleHeader from "./block/CmsBlockArticleHeader.vue";
import CmsBlockCategoryNavigation from "./block/CmsBlockCategoryNavigation.vue";
// import CmsBlockCenterText from "./block/CmsBlockCenterText.vue";
// import CmsBlockCrossSelling from "./block/CmsBlockCrossSelling.vue";
// import CmsBlockCustomForm from "./block/CmsBlockCustomForm.vue";
// import CmsBlockDefault from "./block/CmsBlockDefault.vue";
// import CmsBlockForm from "./block/CmsBlockForm.vue";
import CmsBlockFormMaas from "./block/CmsBlockFormMaas.vue";
// import CmsBlockGalleryBuybox from "./block/CmsBlockGalleryBuybox.vue";
// import CmsBlockImage from "./block/CmsBlockImage.vue";
// import CmsBlockImageBubbleRow from "./block/CmsBlockImageBubbleRow.vue";
// import CmsBlockImageCover from "./block/CmsBlockImageCover.vue";
// import CmsBlockImageFourColumn from "./block/CmsBlockImageFourColumn.vue";
import CmsBlockImageGallery from "./block/CmsBlockImageGallery.vue";
// import CmsBlockImageHighlightRow from "./block/CmsBlockImageHighlightRow.vue";
// import CmsBlockImageSimpleGrid from "./block/CmsBlockImageSimpleGrid.vue";
import CmsBlockImageSlider from "./block/CmsBlockImageSlider.vue";
// import CmsBlockImageText from "./block/CmsBlockImageText.vue";
// import CmsBlockImageTextBubble from "./block/CmsBlockImageTextBubble.vue";
// import CmsBlockImageTextCover from "./block/CmsBlockImageTextCover.vue";
// import CmsBlockImageTextGallery from "./block/CmsBlockImageTextGallery.vue";
// import CmsBlockImageTextRow from "./block/CmsBlockImageTextRow.vue";
// import CmsBlockImageThreeColumn from "./block/CmsBlockImageThreeColumn.vue";
// import CmsBlockImageThreeCover from "./block/CmsBlockImageThreeCover.vue";
// import CmsBlockImageTwoColumn from "./block/CmsBlockImageTwoColumn.vue";
import CmsBlockIntro from "./block/CmsBlockIntro.vue";
import CmsBlockMaasAccordion from "./block/CmsBlockMaasAccordion.vue";
import CmsBlockMaasCatalogDetail from "./block/CmsBlockMaasCatalogDetail.vue";
import CmsBlockMaasJob from "./block/CmsBlockMaasJob.vue";
import CmsBlockMaasJobDetail from "./block/CmsBlockMaasJobDetail.vue";
import CmsBlockMaasStores from "./block/CmsBlockMaasStores.vue";
import CmsBlockMaasStoresDetail from "./block/CmsBlockMaasStoresDetail.vue";
import CmsBlockMaasTextImage from "./block/CmsBlockMaasTextImage.vue";
import CmsBlockMagazineListBlock from "./block/CmsBlockMagazineListBlock.vue";
// import CmsBlockProductDescriptionReviews from "./block/CmsBlockProductDescriptionReviews.vue";
// import CmsBlockProductHeading from "./block/CmsBlockProductHeading.vue";
import CmsBlockProductListing from "./block/CmsBlockProductListing.vue";
import CmsBlockProductlook from "./block/CmsBlockProductlook.vue";
import CmsBlockProductSlider from "./block/CmsBlockProductSlider.vue";
// import CmsBlockProductThreeColumn from "./block/CmsBlockProductThreeColumn.vue";
import CmsBlockQuote from "./block/CmsBlockQuote.vue";
import CmsBlockSidebarFilter from "./block/CmsBlockSidebarFilter.vue";
import CmsBlockStageSlider from "./block/CmsBlockStageSlider.vue";
import CmsBlockTeaserCategory from "./block/CmsBlockTeaserCategory.vue";
import CmsBlockTeaserIcon from "./block/CmsBlockTeaserIcon.vue";
import CmsBlockTeaserInteractive from "./block/CmsBlockTeaserInteractive.vue";
// import CmsBlockText from "./block/CmsBlockText.vue";
// import CmsBlockTextHero from "./block/CmsBlockTextHero.vue";
// import CmsBlockTextOnImage from "./block/CmsBlockTextOnImage.vue";
// import CmsBlockTextTeaser from "./block/CmsBlockTextTeaser.vue";
// import CmsBlockTextTeaserSection from "./block/CmsBlockTextTeaserSection.vue";
// import CmsBlockTextThreeColumn from "./block/CmsBlockTextThreeColumn.vue";
// import CmsBlockTextTwoColumn from "./block/CmsBlockTextTwoColumn.vue";
// import CmsBlockVimeoVideo from "./block/CmsBlockVimeoVideo.vue";
// import CmsBlockYoutubeVideo from "./block/CmsBlockYoutubeVideo.vue";

// List of components using SharedCmsImage (since this is using SharedProxyImg as server component)
const staticComponents = [
  CmsBlockArticleHeader,
  CmsBlockCategoryNavigation,
  // CmsBlockCenterText,
  // CmsBlockCrossSelling,
  // CmsBlockCustomForm,
  // CmsBlockDefault,
  // CmsBlockForm,
  CmsBlockFormMaas,
  // CmsBlockGalleryBuybox,
  // CmsBlockImage,
  // CmsBlockImageBubbleRow,
  // CmsBlockImageCover,
  // CmsBlockImageFourColumn,
  CmsBlockImageGallery,
  // CmsBlockImageHighlightRow,
  // CmsBlockImageSimpleGrid,
  CmsBlockImageSlider,
  // CmsBlockImageText,
  // CmsBlockImageTextBubble,
  // CmsBlockImageTextCover,
  // CmsBlockImageTextGallery,
  // CmsBlockImageTextRow,
  // CmsBlockImageThreeColumn,
  // CmsBlockImageThreeCover,
  // CmsBlockImageTwoColumn,
  CmsBlockIntro,
  CmsBlockMaasAccordion,
  CmsBlockMaasCatalogDetail,
  CmsBlockMaasJob,
  CmsBlockMaasJobDetail,
  CmsBlockMaasStores,
  CmsBlockMaasStoresDetail,
  CmsBlockMaasTextImage,
  CmsBlockMagazineListBlock,
  // CmsBlockProductDescriptionReviews,
  // CmsBlockProductHeading,
  CmsBlockProductListing,
  CmsBlockProductlook,
  CmsBlockProductSlider,
  // CmsBlockProductThreeColumn,
  CmsBlockQuote,
  CmsBlockSidebarFilter,
  CmsBlockStageSlider,
  CmsBlockTeaserCategory,
  CmsBlockTeaserIcon,
  CmsBlockTeaserInteractive,
  // CmsBlockText,
  // CmsBlockTextHero,
  // CmsBlockTextOnImage,
  // CmsBlockTextTeaser,
  // CmsBlockTextTeaserSection,
  // CmsBlockTextThreeColumn,
  // CmsBlockTextTwoColumn,
  // CmsBlockVimeoVideo,
  // CmsBlockYoutubeVideo,
];

const props = defineProps<{
  content: CmsBlock;
  blockPosition?: number;
}>();

const isStatic = (name: string = "") => {
  return staticComponents.map((x) => x.__name).includes(name);
};

/**
 * taken from https://github.com/shopware/frontends/blob/main/packages/composables/src/index.ts
 *
 * @see https://github.com/shopware/frontends/blob/782ef4d417dce6e6d60992bd54f876aa4bc5f45d/packages/composables/src/index.ts#L65
 */
const getName = () => {
  const componentName = props.content.type;
  const type =
    props.content.apiAlias === "cms_block" ? "Block" : props.content.apiAlias === "cms_section" ? "Section" : "Element";
  const componentNameToResolve = pascalCase(`Cms-${type}-${componentName}`);

  return componentNameToResolve;
};

const getStyleAndClassProperty = () => {
  const { cssClasses, layoutStyles } = getCmsLayoutConfiguration(props.content);

  if (layoutStyles.backgroundImage) {
    layoutStyles.backgroundImage = getBackgroundImageUrl(layoutStyles.backgroundImage, props.content);
  }

  const containerStyles = {
    backgroundColor: layoutStyles.backgroundColor,
    backgroundImage: layoutStyles.backgroundImage,
  };

  layoutStyles.backgroundColor = null;
  layoutStyles.backgroundImage = null;

  return {
    style: twMerge(layoutStyles, containerStyles),
    class: cssClasses,
  };
};

const p = getStyleAndClassProperty();

const DynamicRender = () => {
  const { resolvedComponent, isResolved } = resolveCmsComponent(props.content);

  if (resolvedComponent && isResolved) {
    return h(resolvedComponent, {
      content: props.content,
      style: p.style,
      class: p.class,
      blockPosition: props.blockPosition,
    });
  }
};
</script>

<template>
  <DynamicRender v-if="isStatic(getName()) === false" />

  <!--  This is also leading to hydration problems -->
  <!--  <template v-for="comp in staticComponents" :key="comp.__name">-->
  <!--    <component :is="getName()" :content="props.content" :class="p.class" :blockPosition="props.blockPosition" :style="p.style" v-if="getName() === comp.__name"  />-->
  <!--  </template>-->

  <CmsBlockArticleHeader
    v-else-if="getName() === 'CmsBlockArticleHeader'"
    :blockPosition="props.blockPosition"
    :class="p.class"
    :content="props.content"
    :style="p.style"
  />
  <CmsBlockCategoryNavigation
    v-else-if="getName() === 'CmsBlockCategoryNavigation'"
    :blockPosition="props.blockPosition"
    :class="p.class"
    :content="props.content"
    :style="p.style"
  />
  <CmsBlockFormMaas
    v-else-if="getName() === 'CmsBlockFormMaas'"
    :blockPosition="props.blockPosition"
    :class="p.class"
    :content="props.content"
    :style="p.style"
  />
  <CmsBlockImageGallery
    v-else-if="getName() === 'CmsBlockImageGallery'"
    :blockPosition="props.blockPosition"
    :class="p.class"
    :content="props.content"
    :style="p.style"
  />
  <CmsBlockImageSlider
    v-else-if="getName() === 'CmsBlockImageSlider'"
    :blockPosition="props.blockPosition"
    :class="p.class"
    :content="props.content"
    :style="p.style"
  />
  <CmsBlockIntro
    v-else-if="getName() === 'CmsBlockIntro'"
    :blockPosition="props.blockPosition"
    :class="p.class"
    :content="props.content"
    :style="p.style"
  />
  <CmsBlockMaasAccordion
    v-else-if="getName() === 'CmsBlockMaasAccordion'"
    :blockPosition="props.blockPosition"
    :class="p.class"
    :content="props.content"
    :style="p.style"
  />
  <CmsBlockMaasCatalogDetail
    v-else-if="getName() === 'CmsBlockMaasCatalogDetail'"
    :blockPosition="props.blockPosition"
    :class="p.class"
    :content="props.content"
    :style="p.style"
  />
  <CmsBlockMaasJob
    v-else-if="getName() === 'CmsBlockMaasJob'"
    :blockPosition="props.blockPosition"
    :class="p.class"
    :content="props.content"
    :style="p.style"
  />
  <CmsBlockMaasJobDetail
    v-else-if="getName() === 'CmsBlockMaasJobDetail'"
    :blockPosition="props.blockPosition"
    :class="p.class"
    :content="props.content"
    :style="p.style"
  />
  <CmsBlockMaasStores
    v-else-if="getName() === 'CmsBlockMaasStores'"
    :blockPosition="props.blockPosition"
    :class="p.class"
    :content="props.content"
    :style="p.style"
  />
  <CmsBlockMaasStoresDetail
    v-else-if="getName() === 'CmsBlockMaasStoresDetail'"
    :blockPosition="props.blockPosition"
    :class="p.class"
    :content="props.content"
    :style="p.style"
  />
  <CmsBlockMaasTextImage
    v-else-if="getName() === 'CmsBlockMaasTextImage'"
    :blockPosition="props.blockPosition"
    :class="p.class"
    :content="props.content"
    :style="p.style"
  />
  <CmsBlockMagazineListBlock
    v-else-if="getName() === 'CmsBlockMagazineListBlock'"
    :blockPosition="props.blockPosition"
    :class="p.class"
    :content="props.content"
    :style="p.style"
  />
  <CmsBlockProductListing
    v-else-if="getName() === 'CmsBlockProductListing'"
    :blockPosition="props.blockPosition"
    :class="p.class"
    :content="props.content"
    :style="p.style"
  />
  <CmsBlockProductlook
    v-else-if="getName() === 'CmsBlockProductlook'"
    :blockPosition="props.blockPosition"
    :class="p.class"
    :content="props.content"
    :style="p.style"
  />
  <CmsBlockProductSlider
    v-else-if="getName() === 'CmsBlockProductSlider'"
    :blockPosition="props.blockPosition"
    :class="p.class"
    :content="props.content"
    :style="p.style"
  />
  <CmsBlockQuote
    v-else-if="getName() === 'CmsBlockQuote'"
    :blockPosition="props.blockPosition"
    :class="p.class"
    :content="props.content"
    :style="p.style"
  />
  <CmsBlockSidebarFilter
    v-else-if="getName() === 'CmsBlockSidebarFilter'"
    :blockPosition="props.blockPosition"
    :class="p.class"
    :content="props.content"
    :style="p.style"
  />
  <CmsBlockStageSlider
    v-else-if="getName() === 'CmsBlockStageSlider'"
    :blockPosition="props.blockPosition"
    :class="p.class"
    :content="props.content"
    :style="p.style"
  />
  <CmsBlockTeaserCategory
    v-else-if="getName() === 'CmsBlockTeaserCategory'"
    :blockPosition="props.blockPosition"
    :class="p.class"
    :content="props.content"
    :style="p.style"
  />
  <CmsBlockTeaserIcon
    v-else-if="getName() === 'CmsBlockTeaserIcon'"
    :blockPosition="props.blockPosition"
    :class="p.class"
    :content="props.content"
    :style="p.style"
  />
  <CmsBlockTeaserInteractive
    v-else-if="getName() === 'CmsBlockTeaserInteractive'"
    :blockPosition="props.blockPosition"
    :class="p.class"
    :content="props.content"
    :style="p.style"
  />
</template>
